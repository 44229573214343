<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div>
        <b-button
          v-if="true"
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="redirectList"
        >
          {{ $t("Back to List") }}
        </b-button>
        <b-button
          v-else
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="clearForm"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t("Cancel") }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 12px"
      >
        <b-button
          :disabled="loadingFor.isLoading"
          style="width: 135px; height: 40px; gap: 10px; background: #6f1214 !important;"
          class="font-medium-1 p-0 d-flex justify-content-center align-items-center"
          @click="submitByAction(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_REJECT_QUOTE)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_REJECT_QUOTE.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_REJECT_QUOTE.title) }}
          </span>
        </b-button>
        <b-button
          :disabled="loadingFor.isLoading"
          class="font-medium-1 p-0 d-flex justify-content-center align-items-center"
          style="width: 160px; min-height: 40px; gap: 10px"
          @click="submitByAction(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ.action"
            small
            variant="secondary"
          />
          <span v-else>
            <feather-icon
              icon="LLoadIcon"
              size="16"
            />
            <span>
              {{ $t(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ.title) }}
            </span>
          </span>

        </b-button>
        <b-button
          :disabled="loadingFor.isLoading"
          style="width: 160px; min-height: 40px"
          class="font-medium-1 p-0 d-flex justify-content-center align-items-center"
          @click="submit(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_SEND_PO.action)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_SEND_PO.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_SEND_PO.title) }}
          </span>
        </b-button>
        <b-button
          :disabled="loadingFor.isLoading"
          style="width: 160px; min-height: 40px; gap: 10px"
          class="font-medium-1 p-0 d-flex justify-content-center align-items-center"
          variant="success"
          @click="submit(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_APPROVE_PO.action)"
        >
          <feather-icon
            icon="LSignatureIcon"
            size="16"
          />
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_APPROVE_PO.action"
            small
            variant="secondary"
          />
          <span>
            {{ $t(RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_APPROVE_PO.title) }}
          </span>
        </b-button>
      </div>
    </div>
  </portal>
</template>

<script>

import { BButton, BSpinner } from 'bootstrap-vue'
import { scrollToError, mapDate } from '@core/utils/utils'
import { mapGetters } from 'vuex'
import mainConfig from '@/views/main/orders/view/purchase/config'
import jsonToFormData from '@/libs/jsonToFormData'
import config from '../config'

export default {
  name: 'FormBottom',
  components: {
    BButton,
    BSpinner,
  },
  props: {
    formRef: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingFor: {
        isLoading: false,
        action: null,
      },
    }
  },
  computed: {
    ...mapGetters('purchase-cr', ['getTotalDataOfForms']),
    disabled() {
      return true
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    receiveWarehouseForm() {
      return this.$store.state[this.MODULE_NAME].receiveWarehouseForm
    },
    receiveAttachments() {
      return this.$store.state[this.MODULE_NAME].receiveAttachments
    },
    orderItemsOtherChargesTable() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
    },
    orderItemsOtherChargesTableRef() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTableRef
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    purchaseFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].purchaseFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.getTotalDataOfForms) !== this.purchaseFormClone
    },
    customerInformationForm() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
  },
  methods: {
    redirectList() {
      this.$router.push({ name: 'home-orders-purchase-cr-list' })
    },
    redirectToRFQSend(id, action) {
      if (action === this.RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ.action) {
        this.$router.push({ name: 'direct-request-for-quote', params: { id } })
      } else {
        this.$router.push({ name: 'purchase-order-for-supplier', params: { id } })
      }
    },
    clearForm() {},
    submit(action) {
      this.formRef.receivedQuoteFromSupplierForm.validate().then(success => {
        if (success) {
          this.$emit('onSubmitSuccess')

          const {
            quote,
            receiveAttachments,
            receiveWarehouseForm,
            orderItemsOtherChargesTable,
          } = this

          // eslint-disable-next-line array-callback-return
          const OtherChargesTableHasDiscountAndSubtotal = orderItemsOtherChargesTable.otherCharges.every(({ subtotal, discount }) => {
            if (subtotal || discount) {
              return true
            }
          })

          if (!OtherChargesTableHasDiscountAndSubtotal) {
            this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE_ERROR`, 'Fill in Subtotal and Discount')
            this.orderItemsOtherChargesTableRef.scrollIntoView({ behavior: 'smooth' })

            setTimeout(() => {
              this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE_ERROR`, '')
            }, 5000)
          } else {
            this.loadingFor = {
              // isLoading: true,
              action,
            }
            const totalData = jsonToFormData({
              id: quote.id,
              _method: 'PUT',
              action,
              supplier_id: quote.customer_id,
              billing_contact_id: quote.billing_contact.id,
              payment_term_id: quote.payment_term_id,
              required_by: quote.required_by,
              products: quote.products.map(({
                product_id, amount, price, available_on, discount, supplier_sku,
              }) => ({
                id: product_id, amount, price, supplier_sku, available_on: mapDate(available_on), discount: discount ?? 0,
              })),
              other_charges: orderItemsOtherChargesTable.otherCharges.map(({
                id, name, unit, subtotal, discount, price, taxes,
              }) => ({
                name, unit, subtotal, discount: discount ?? 0, id, price, tax: taxes ?? 0,
              })),
              receiving_warehouse_id: receiveWarehouseForm.receiving_warehouse_id.id,
              dispatch_method_id: receiveWarehouseForm.receive_method.id,
              dispatch_contact_id: receiveWarehouseForm.dispatch_contact_id.id,
              external_shipping_notes: receiveWarehouseForm.external_shipping_notes,
              external_po_notes: receiveWarehouseForm.external_po_notes,
              attachments: receiveAttachments.filter(({ id }) => !id),
            })
            this.sendNotification(this, { id: quote.id, data: totalData }, `${this.MODULE_NAME}/createByID`)
              .then(({ data }) => {
                const { id } = data.data

                this.$emit('clear')
                this.redirectToRFQSend(id)
              })
              .catch(err => {
                this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
              }).finally(() => {
                this.loadingFor = {
                  isLoading: false,
                  action: null,
                }
              })
          }
        }
        scrollToError(this, this.formRef.receivedQuoteFromSupplierForm)
      })
    },
    submitByAction(item) {
      const { id } = this.quote
      const { action, successMsg } = item

      this.$emit('onSubmitSuccess')

      this.loadingFor = {
        isLoading: true,
        action,
      }

      this.sendNotification(this, { id, body: { id, action } }, `${this.MODULE_NAME}/patch`, successMsg)
        .then(() => {
          this.$emit('clear')
          this.redirectToRFQSend(id, action)
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })
    },
  },
  setup() {
    const {
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_SEND_PO,
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_APPROVE_PO,
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ,
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_REJECT_QUOTE,
    } = config()

    const {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    } = mainConfig()

    return {
      mapDate,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_SEND_PO,
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_APPROVE_PO,
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ,
      RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_REJECT_QUOTE,
    }
  },
}
</script>
