<template>
  <div>
    <h3 class="font-weight-bolder mb-1">
      {{ $t(ATTACHMENTS_TITLE) }}
    </h3>
    <div class="form-wrapper">
      <div class="l-file-upload">
        <div class="d-flex justify-content-end mb-1">
          <feather-icon
            style="min-width: 37px; min-height: 37px"
            icon="LAddButtonIcon"
            size="24"
            class="cursor-pointer feather-add-icon"
            @click="$refs.refLFileUploadInput.click()"
          />
        </div>
        <!-- ===================================== -->
        <div class="upload-file">
          <div class="">
            <input
              v-show="false"
              ref="refLFileUploadInput"
              :accept="formattedAcceptProps"
              type="file"
              @input="fileInputChanged"
            >
          </div>
        </div>
        <div>
          <b-table
            v-if="!isLoading"
            :items="receiveAttachments"
            :fields="uploadAttachmentTableCol"
            class="upload-attachment-table"
            :show-empty="true"
            :empty-text="'None found'"
          >
            <template #cell(action)="data">
              <div
                class="d-flex"
                style="gap: 8px"
              >
                <a @click="showAttachment(data.item)">
                  <feather-icon
                    icon="LEyeIcon"
                    size="28"
                    style="padding: 5px"
                    class="cursor-pointer border-dotted feather-eye-icon defaultIconColorTwo"
                  />
                </a>
                <feather-icon
                  icon="LTrashIcon"
                  size="28"
                  style="padding: 5px"
                  class="cursor-pointer mr-1 border-dotted feather-trash-icon"
                  @click="deleteAttachment(data.item.id, data.index)"
                />
              </div>
            </template>
            <template #cell(name)="{ item }">
              {{ item.id ? item.name : item.file.name }}
            </template>
            <template #cell(date)="{ item }">
              {{ item.id ? mapDate(item.created_at) : item.uploadedDate }}
            </template>
            <template #cell(type)="{ item }">
              {{ item.id ? item.mime_type : item.file.type }}
            </template>
            <template #cell(category)="{ item }">
              {{ item.id ? item.attachment_category : getCategoryName(item.attachment_category_id) }}
            </template>
          </b-table>
          <div
            v-if="isLoading"
            class="d-flex justify-content-center my-2"
          >
            <b-spinner />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="attachment-create-modal"
      centered
      hide-header
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Upload"
      ok-variant="success"
      :ok-disabled="isOkUploadBtnActive"
      :cancel-disabled="isCancelUploadBtnActive"
      cancel-variant="outline-primary"
      @hidden="clearData"
      @ok="uploadFile"
    >
      <b-overlay :show="isUploading">
        <div class="l-modal__container">
          <b-row>
            <b-col cols="12">
              <h3 class="l-modal__title">
                {{ $t("What category is this file?") }}
              </h3>
            </b-col>
            <b-col>
              <l-select
                v-model="sendData.attachment_category_id"
                name="attachment_category_id"
                :field="{
                  options: { transition: '' },
                  store: 'attachmentCategoryListContact',
                }"
              />
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BModal,
  BOverlay,
  BSpinner,
  VBTooltip,
  BTable,
} from 'bootstrap-vue'
import { getCurrentFormattedDate, mapDate } from '@core/utils/utils'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import config from '../config'
import mainConfig from '../../../../config'

export default {
  name: 'Attachment',
  components: {
    BRow,
    BCol,
    BModal,
    BOverlay,
    BSpinner,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      isUploading: false,
      isLoading: false,
      chosenFiles: null,
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      sendData: {
        attachment_category_id: null,
        file: null,
        uploadedDate: getCurrentFormattedDate(),
      },
    }
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray
    },
    customerID() {
      return this.$store.state[this.MODULE_NAME].quote.customer_id
    },
    attachmentCategoryListContact() {
      return this.$store.state.listModule.attachmentCategoryListContact ?? []
    },
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
    receiveAttachments() {
      return this.$store.state[this.MODULE_NAME].receiveAttachments ?? []
    },
  },
  methods: {
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    showAttachment(item) {
      if (item.id) {
        window.open(item.url, '_blank')
      } else {
        window.open(
          (window.URL || window.webkitURL).createObjectURL(item.file),
          '_blank',
        )
      }
    },
    fileInputChanged(e) {
      this.chosenFiles = e.target.files
      this.showModalToChoseCategory()
    },
    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refLFileUploadInput.value = null
    },
    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
      }
    },
    getCategoryName(id) {
      return (
          this.attachmentCategoryListContact.find(item => +item.id === +id)
            ?.name ?? 'no category'
      )
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.isUploading = false
        this.hideModalToChoseCategory()
      }
      return isErrorAvailable
    },
    uploadFile() {
      const file = this.chosenFiles[0]
      if (this.checkTheFileType(file.type)) {
        return
      }
      if (file.size > 4 * 1024 * 1024) {
        // 4MB limit
        this.isUploading = false
        this.hideModalToChoseCategory()
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      this.isUploading = true
      this.receiveAttachments.push(this.mapDataObject())
      this.isUploading = false
    },
    deleteAttachment(id, index) {
      if (id) {
        this.isLoading = true
        this.$store
          .dispatch(`${this.MODULE_NAME}/deleteAttachment`, { id })
          .catch(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: i18n.t('Error Deleting This file', {
                  module: this.MODULE_NAME,
                }),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(({ data: { data } }) => {
              this.$store.commit(`${this.MODULE_NAME}/SET_RECEIVE_ATTACHMENTS`, data.attachments)
            }).finally(() => {
              this.isLoading = false
            })
          })
      } else {
        this.receiveAttachments.splice(index, 1)
      }
    },
  },
  setup() {
    const { uploadAttachmentTableCol, ATTACHMENTS_TITLE } = config()
    const { MODULE_NAME, MODULE_NAME_ATTACHMENT } = mainConfig()

    return {
      mapDate,
      MODULE_NAME,
      ATTACHMENTS_TITLE,
      MODULE_NAME_ATTACHMENT,
      uploadAttachmentTableCol,
    }
  },
}
</script>
<style lang="scss">
.upload-attachment-table {
    tbody tr td {
      padding: 5px 5px 5px 20px
    }

  & .b-table-empty-row {
    td {
      padding: 5px !important;

      div {
        margin: 0 !important;
      }
    }
  }
}
</style>
