<template>
  <div
    class="d-flex align-items-center"
    style="gap: 8px"
  >
    <b class="d-inline-block ml-2">
      {{ label }}
    </b>
    <div
      :class="item[keyNameOfAmount] === minQty ? 'disable' : ''"
      class="cursor-pointer"
      @click.stop
    >
      <feather-icon
        icon="LMinusButtonIcon"
        size="36"
        @click="incrementAndDecrement(DECREMENT_ACTION)"
      />
    </div>
    <div
      class="border d-flex justify-content-center align-items-center"
      style="height: 36px; width: 55px; border-radius: 5px"
    >
      {{ item[keyNameOfAmount] }}
    </div>
    <div
      :class="getCopyOfAmount === item[keyNameOfAmount] ? 'disable' : ''"
      class="cursor-pointer"
      @click.stop
    >
      <feather-icon
        icon="LAddButtonGreenIcon"
        size="36"
        @click="incrementAndDecrement(INCREMENT_ACTION)"
      />
    </div>
  </div>
</template>

<script>
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'

export default {
  name: 'IncrementDecrementAmount',
  props: {
    item: {
      type: Object,
      required: true,
    },
    keyNameOfAmount: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: () => '',
    },
    minQty: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      getCopyOfAmount: this.item[this.keyNameOfAmount],
    }
  },
  methods: {
    incrementAndDecrement(action) {
      if (action === this.INCREMENT_ACTION) this.item[this.keyNameOfAmount] += 1
      if (action === this.DECREMENT_ACTION) this.item[this.keyNameOfAmount] -= 1
    },
  },
  setup() {
    const INCREMENT_ACTION = 'plus'
    const DECREMENT_ACTION = 'minus'

    return {
      INCREMENT_ACTION,
      DECREMENT_ACTION,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
<style scoped lang="scss">
.disable {
  position: relative;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
    opacity: 0.5;
    background-color: #efefef;
  }
}
</style>
