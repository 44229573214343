var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t(_vm.ATTACHMENTS_TITLE))+" ")]),_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"l-file-upload"},[_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('feather-icon',{staticClass:"cursor-pointer feather-add-icon",staticStyle:{"min-width":"37px","min-height":"37px"},attrs:{"icon":"LAddButtonIcon","size":"24"},on:{"click":function($event){return _vm.$refs.refLFileUploadInput.click()}}})],1),_c('div',{staticClass:"upload-file"},[_c('div',{},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"refLFileUploadInput",attrs:{"accept":_vm.formattedAcceptProps,"type":"file"},on:{"input":_vm.fileInputChanged}})])]),_c('div',[(!_vm.isLoading)?_c('b-table',{staticClass:"upload-attachment-table",attrs:{"items":_vm.receiveAttachments,"fields":_vm.uploadAttachmentTableCol,"show-empty":true,"empty-text":'None found'},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"8px"}},[_c('a',{on:{"click":function($event){return _vm.showAttachment(data.item)}}},[_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-eye-icon defaultIconColorTwo",staticStyle:{"padding":"5px"},attrs:{"icon":"LEyeIcon","size":"28"}})],1),_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted feather-trash-icon",staticStyle:{"padding":"5px"},attrs:{"icon":"LTrashIcon","size":"28"},on:{"click":function($event){return _vm.deleteAttachment(data.item.id, data.index)}}})],1)]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id ? item.name : item.file.name)+" ")]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id ? _vm.mapDate(item.created_at) : item.uploadedDate)+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id ? item.mime_type : item.file.type)+" ")]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id ? item.attachment_category : _vm.getCategoryName(item.attachment_category_id))+" ")]}}],null,false,3589146835)}):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-spinner')],1):_vm._e()],1)])]),_c('b-modal',{attrs:{"id":"attachment-create-modal","centered":"","hide-header":"","body-class":"l-modal__body","footer-class":"l-modal-footer","ok-title":"Upload","ok-variant":"success","ok-disabled":_vm.isOkUploadBtnActive,"cancel-disabled":_vm.isCancelUploadBtnActive,"cancel-variant":"outline-primary"},on:{"hidden":_vm.clearData,"ok":_vm.uploadFile}},[_c('b-overlay',{attrs:{"show":_vm.isUploading}},[_c('div',{staticClass:"l-modal__container"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"l-modal__title"},[_vm._v(" "+_vm._s(_vm.$t("What category is this file?"))+" ")])]),_c('b-col',[_c('l-select',{attrs:{"name":"attachment_category_id","field":{
                options: { transition: '' },
                store: 'attachmentCategoryListContact',
              }},model:{value:(_vm.sendData.attachment_category_id),callback:function ($$v) {_vm.$set(_vm.sendData, "attachment_category_id", $$v)},expression:"sendData.attachment_category_id"}})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }