<template>
  <div>
    <portal to="body-top">
      <div class="d-flex justify-content-center">
        <b-skeleton
          width="40%"
          height="26px"
          class="mb-0"
        />
      </div>
      <quote-status-bar-skeleton />
    </portal>
    <div>
      <div class="form-wrapper mb-1">
        <h3 class="font-weight-bolder mb-1">
          {{ $t(ORDER_INFORMATION_TITLE) }}
        </h3>

        <div>
          <b-row>
            <b-col
              v-for="(_, indx) in Array(12)"
              :key="indx"
              cols="6"
            >
              <b-row class="mb-1">
                <b-col cols="6">
                  <b-skeleton
                    width="50%"
                    height="16px"
                    class="mb-0"
                  />
                </b-col>
                <b-col cols="6">
                  <b-skeleton
                    width="50%"
                    height="16px"
                    class="mb-0"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="form-wrapper mb-1">
        <h3 class="font-weight-bolder mb-1">
          {{ $t(ORDER_INFORMATION_RECEIVE_TITLE) }}
        </h3>

        <div>
          <b-row>
            <b-col
              v-for="field in ['receiving_warehouse_id', 'dispatch_contact_id', 'receive_method', 'external_shipping_notes']"
              :key="field"
              cols="6"
              style="height: 70px"
              class="mt-2"
            >
              <span class="inputLabel">{{ receiveWarehouseFields[field].label }}</span>
              <b-skeleton
                height="40px"
                style="border-radius: 5px"
              />
            </b-col>
          </b-row>

        </div>
      </div>
      <div class="mb-1">
        <h3 class="font-weight-bolder mb-1">
          {{ $t(STOCK_ITEMS) }}
        </h3>

        <div class="form-wrapper">
          <b-skeleton
            width="100%"
            height="33px"
            class="mb-0"
          />
        </div>

      </div>
      <div class="mb-1">
        <h3 class="font-weight-bolder mb-1">
          {{ $t(OTHER_CHARGES_ITEMS) }}
        </h3>
        <b-table-simple class="form-wrapper">
          <b-thead>
            <b-tr>
              <b-td
                v-for="({label, thStyle}, idx) in tableColumnsOtherCharges"
                :key="idx"
                class="font-weight-bolder border-top-0"
                :style="thStyle"
              >
                {{ label }}
              </b-td>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td
                v-for="(_, idx) in tableColumnsOtherCharges"
                :key="idx"
                class="p-1"
              >
                <b-skeleton
                  width="100%"
                  height="16px"
                  class="mb-0"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <div class="mb-1">
        <h3 class="font-weight-bolder mb-1">
          {{ $t(ATTACHMENTS_TITLE) }}
        </h3>
        <b-table-simple class="form-wrapper">
          <b-thead>
            <b-tr>
              <b-td
                v-for="({label, thStyle}, idx) in uploadAttachmentTableCol"
                :key="idx"
                class="font-weight-bolder border-top-0"
                :style="thStyle"
              >
                {{ label }}
              </b-td>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td
                v-for="(_, idx) in uploadAttachmentTableCol"
                :key="idx"
                class="p-1"
              >
                <b-skeleton
                  width="100%"
                  height="16px"
                  class="mb-0"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <b-row>
        <b-col cols="6">
          <div class="form-wrapper">
            <h3 class="font-weight-bolder mb-1">
              {{ $t(NOTES) }}
            </h3>
            <b-col
              v-for="field in ['internal_order_reference', 'external_order_notes', 'external_po_notes']"
              :key="field"
              cols="12"
              style="height: 70px"
              class="mt-2"
            >
              <span class="inputLabel">{{ notesFields[field].label }}</span>
              <b-skeleton
                height="40px"
                style="border-radius: 5px"
              />
            </b-col>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-wrapper">
            <h3 class="font-weight-bolder mb-1">
              {{ $t(TOTALS) }}
            </h3>
            <div
              class="border"
              style="border-radius: 5px; overflow: hidden"
            >
              <div
                v-for="item in Array(5)"
                :key="item"
              >
                <div
                  style="height: 36px; padding: 8px"
                  class="border-bottom"
                >
                  <b-skeleton
                    height="20px"
                    style="border-radius: 5px"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BSkeleton, BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTd,
} from 'bootstrap-vue'
import QuoteStatusBarSkeleton from '@/views/main/orders/components/use-as-global/QuoteStatusBarSkeleton.vue'
import config from '../config'
import mainConfig from '../../../../config'

export default {
  name: 'Skeleton',
  components: {
    BRow,
    BCol,
    BSkeleton,
    QuoteStatusBarSkeleton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
  },
  setup() {
    const {
      NOTES,
      TOTALS,
      STOCK_ITEMS,
      notesFields,
      ATTACHMENTS_TITLE,
      OTHER_CHARGES_ITEMS,
      receiveWarehouseFields,
      ORDER_INFORMATION_TITLE,
      uploadAttachmentTableCol,
      ORDER_INFORMATION_RECEIVE_TITLE,
    } = config()

    const {
      tableColumnsOtherCharges,
    } = mainConfig()

    return {
      NOTES,
      TOTALS,
      STOCK_ITEMS,
      notesFields,
      ATTACHMENTS_TITLE,
      OTHER_CHARGES_ITEMS,
      receiveWarehouseFields,
      ORDER_INFORMATION_TITLE,
      tableColumnsOtherCharges,
      uploadAttachmentTableCol,
      ORDER_INFORMATION_RECEIVE_TITLE,
    }
  },
}
</script>
