<template>
  <div class="form-wrapper">
    <h3 class="font-weight-bolder mb-1">
      {{ $t(ORDER_INFORMATION_TITLE) }}
    </h3>
    <b-row>
      <b-col cols="6">
        <b-row>
          <b-col
            v-for="({key , label}, idx) in orderInformationObjectForRender"
            :key="idx"
            cols="12"
          >
            <b-row style="margin-bottom: 8px">
              <b-col cols="4">
                <b>{{ label }}</b>
              </b-col>
              <b-col cols="8">
                <span>{{ getValueFromGivenObjectByKey(quote, key) }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6" />
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '../config'
import mainConfig from '../../../../config'

export default {
  name: 'OrderInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  setup() {
    const {
      ORDER_INFORMATION_TITLE,
      orderInformationObjectForRender,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      ORDER_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      orderInformationObjectForRender,
    }
  },
}
</script>
