import {
  SELECT_CHILD_INPUT, SELECT_INPUT, TEXTAREA_INPUT, TEXT_INPUT, L_CURRENCY_MASK_INPUT, FLAT_PICKER, CHECKBOX,
} from '@/views/components/DynamicForm/constants'
import { formatNumberToCurrencyView, getValueFromGivenObjectByKey } from '@core/utils/utils'

export default function config() {
  const ORDER_INFORMATION_TITLE = 'Order Information'
  const ORDER_INFORMATION_SUPPLIER_TITLE = 'Supplier Information'
  const ORDER_INFORMATION_RECEIVE_TITLE = 'Receive'

  const NOTES = 'Notes'
  const TOTALS = 'Totals'
  const STOCK_ITEMS = 'Stock Items'
  const ATTACHMENTS_TITLE = 'Attachments'
  const OTHER_CHARGES_ITEMS = 'Other Charges/Items'

  const ITEMS_ROW_ITEM_TITLE = 'Catalog Item '

  const orderInformationObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Supplier',
    },
    {
      key: 'required_by',
      label: 'Required by',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'billing_contact.first_name', '—')} ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name', '')}`,
      label: 'Billing Contact',
    },
    {
      key: 'billing_contact.phone_one',
      label: 'Billing Phone',
    },
    {
      key: 'billing_contact.email',
      label: 'Billing Email',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_address.street', '—')}, 
      ${getValueFromGivenObjectByKey(data, 'billing_address.line2', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.city', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.state.code', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.zip', '')},
      ${getValueFromGivenObjectByKey(data, 'billing_address.country.three_letter', '')}
      `,
      label: 'Billing Address',
    },

  ]

  const receiveWarehouseFields = {
    receiving_warehouse_id: {
      type: SELECT_INPUT,
      label: 'Receive Warehouse',
      store: 'warehouseList',
      options: {
        label: 'label',
      },
      rules: 'required',
    },
    receive_method: {
      type: SELECT_INPUT,
      label: 'Receive Method',
      store: 'dispatchAndReturnMethodsList',
      options: {
        label: 'name',
      },
      rules: 'required',
    },
    dispatch_contact_id: {
      type: SELECT_CHILD_INPUT,
      label: 'Dispatch Contact',
      store: 'orderDispatchList',
      rules: 'required',
      options: {
        label: 'full_name',
      },
    },
    external_shipping_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal Shipping Notes (not visible to the supplier)',
      placeholder: 'Write notes here...',
    },
  }

  const notesFields = {
    internal_order_reference: {
      type: TEXT_INPUT,
      label: 'Internal RFQ Notes',
    },
    external_order_notes: {
      type: TEXT_INPUT,
      label: 'External RFQ Notes (visible to the supplier)',
    },
    external_po_notes: {
      type: TEXT_INPUT,
      label: 'External PO Notes (visible to the supplier)',
    },
  }

  const RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_REJECT_QUOTE = {
    title: 'Reject Quote',
    action: 'reject_quote',
    successMsg: 'Quote Rejected successfully',
  }
  const RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ = {
    title: 'Back to RFQ',
    action: 'back_rfq',
    successMsg: 'RFQ Back successfully',
  }
  const RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_SEND_PO = {
    title: 'Send PO',
    action: 'send_po',
    successMsg: 'PO sent successfully',
  }
  const RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_APPROVE_PO = {
    title: 'Accept Quote',
    action: 'approve_po',
    successMsg: 'PO Approved successfully',
  }

  const uploadAttachmentTableCol = [
    {
      key: 'action',
      label: '',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '10%',
      },
    },
    {
      key: 'name',
      label: 'File Name',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '30%',
      },
    },
    {
      key: 'date',
      label: 'Date',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
    {
      key: 'type',
      label: 'Type',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
    {
      key: 'category',
      label: 'Category',
      thStyle: {
        padding: '5px 5px 5px 20px',
        width: '20%',
      },
    },
  ]

  const stockItemsFields = {
    price: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Quoted Price',
      placeholder: '$0',
    },
    discount: {
      type: TEXT_INPUT,
      label: 'Discounts',
      rules: 'numeric|max_value:100',
    },
    supplier_sku: {
      type: TEXT_INPUT,
      label: 'Supplier SKU',
      placeholder: 'Type...',
    },
    available_on: {
      type: FLAT_PICKER,
      label: 'Available On',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
  }

  const stockItemsItemObject = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'mpn',
      label: 'MPN',
    },
    {
      key: 'brand',
      label: 'Brand',
    },
    {
      key: 'sku',
      label: 'Model',
    },
    {
      key: 'supplier_sku',
      label: 'Supplier SKU',
    },
    {
      key: 'amount',
      label: 'Units',
    },
    {
      key: 'last_price',
      label: 'Last Price',
    },
    {
      key: 'msrp',
      label: 'MSRP',
    },
    {
      key: data => formatNumberToCurrencyView(`${data.replacement_cost}`),
      label: 'Replacement Cost',
    },
    {
      key: 'last_purchase_date',
      label: 'Last Purchase Date',
    },
  ]

  const TOTALS_TOTAL_PRICE_TITLE = 'TOTAL'
  const TOTALS_ITEMS_TOTAL_PRICE_TITLE = 'Items'
  const TOTALS_TAXES_TOTAL_PRICE_TITLE = 'Taxes'
  const TOTALS_DISCOUNT_TOTAL_PRICE_TITLE = 'Discounts'
  const TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE = 'Other Charges'

  // Store Mutations
  const storeMutationsList = [
    {
      mutation: 'GET',
      payload: {},
    },
    {
      mutation: 'SET_RECEIVE_WAREHOUSE_FORM',
      payload: { },
    },
    {
      mutation: 'SET_RECEIVE_ATTACHMENTS',
      payload: [],
    },
    {
      mutation: 'SET_ON_ERROR',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      payload: {
        otherCharges: [],
      },
    },
  ]

  return {
    NOTES,
    TOTALS,
    STOCK_ITEMS,
    notesFields,
    stockItemsFields,
    ATTACHMENTS_TITLE,
    OTHER_CHARGES_ITEMS,
    storeMutationsList,
    stockItemsItemObject,
    ITEMS_ROW_ITEM_TITLE,
    receiveWarehouseFields,
    ORDER_INFORMATION_TITLE,
    uploadAttachmentTableCol,
    orderInformationObjectForRender,
    ORDER_INFORMATION_RECEIVE_TITLE,
    ORDER_INFORMATION_SUPPLIER_TITLE,
    TOTALS_TOTAL_PRICE_TITLE,
    TOTALS_ITEMS_TOTAL_PRICE_TITLE,
    TOTALS_TAXES_TOTAL_PRICE_TITLE,
    TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
    TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
    RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_SEND_PO,
    RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_APPROVE_PO,
    RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_BACK_TO_RFQ,
    RECEIVED_QUOTE_FROM_SUPPLIERS_ACTION_REJECT_QUOTE,
  }
}
