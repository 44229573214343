<template>
  <div>

    <b-row>
      <b-col cols="6">
        <div class="form-wrapper">
          <h3 class="font-weight-bolder text-body">
            {{ $t(NOTES) }}
          </h3>
          <component
            :is="notesFields[field].type"
            v-for="field in ['internal_order_reference', 'external_order_notes']"
            :key="field"
            v-model="quote[field]"
            v-bind="getProps(field, notesFields)"
            :is-editable="true"
          />
          <component
            :is="notesFields['external_po_notes'].type"
            v-model="receiveWarehouse['external_po_notes']"
            v-bind="getProps('external_po_notes', notesFields)"
          />
        </div>
      </b-col>
      <b-col cols="6">

        <div class="form-wrapper">
          <h3 class="font-weight-bolder text-body">
            {{ $t(TOTALS) }}
          </h3>
          <b-list-group class="totals-form-list-group">
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_ITEMS_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(stockItemsPricesTotalSum) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(otherChargesTotalSum) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_DISCOUNT_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(discountTotalSum) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TAXES_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView(taxesTotalSumOf) }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  <b>{{ TOTALS_TOTAL_PRICE_TITLE }}</b>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <b>{{ formatNumberToCurrencyView(totals) }}</b>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>

  </div>

</template>

<script>
import {
  BCol, BRow, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import minConfig from '@/views/main/orders/view/purchase/config'
import { getProps, sumTotalAmountOfGivenArrayByProperty, formatNumberToCurrencyView } from '@core/utils/utils'
// eslint-disable-next-line import/no-named-as-default
import config from '../config'

export default {
  name: 'NotesAndTotals',
  components: {
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    orderItemsOtherChargesTable() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable.otherCharges
    },
    receiveWarehouse() {
      return this.$store.state[this.MODULE_NAME].receiveWarehouseForm
    },
    stockItems() {
      return this.quote.products
    },
    stockItemsPricesTotalSum() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.stockItems.map(item => ({ price: item.price * item.amount })), 'price')
    },
    stockItemsDiscountsTotalSum() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.stockItems, 'discount')
    },
    discountTotalSum() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherChargesTable, 'discount') + +this.stockItemsDiscountsTotalSum
    },
    otherChargesTotalSum() {
      return this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherChargesTable.map(charge => ({
        itemTotalSum: charge.price * charge.unit,
      })), 'itemTotalSum')
    },
    taxesTotalSumOf() {
      const { tax } = this.quote

      const itemPrice = (this.stockItemsPricesTotalSum * tax) / 100
      return itemPrice + this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherChargesTable.map(charge => ({
        taxes: charge.taxes,
      })), 'taxes')

      // return this.sumTotalAmountOfGivenArrayByProperty(this.orderItemsOtherChargesTable.map(charge => ({
      //   // eslint-disable-next-line no-mixed-operators
      //   taxSum: (charge.subtotal - charge.discount) * charge.tax / 100,
      // })), 'taxSum')
    },
    totals() {
      return +this.stockItemsPricesTotalSum + this.otherChargesTotalSum - this.discountTotalSum + this.taxesTotalSumOf
    },
  },
  setup() {
    const {
      TOTALS, NOTES,
      notesFields,
      TOTALS_TOTAL_PRICE_TITLE,
      TOTALS_ITEMS_TOTAL_PRICE_TITLE,
      TOTALS_TAXES_TOTAL_PRICE_TITLE,
      TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
      TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
    } = config()
    const { MODULE_NAME } = minConfig()

    return {
      NOTES,
      TOTALS,
      getProps,
      notesFields,
      MODULE_NAME,
      formatNumberToCurrencyView,
      TOTALS_TOTAL_PRICE_TITLE,
      TOTALS_ITEMS_TOTAL_PRICE_TITLE,
      TOTALS_TAXES_TOTAL_PRICE_TITLE,
      TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
      sumTotalAmountOfGivenArrayByProperty,
      TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
    }
  },
}
</script>
<style lang="scss">
.totals-form-list-group {

  &-item {
  &:nth-child(even) {
    background-color: #FBFBFB;
  }
  padding: 5px 1.25rem !important;

  &:hover {
    background-color: transparent !important;
  }

  &-light {
    background-color: #FBFBFB !important;

    &:hover {
      background-color: #FBFBFB !important;
    }
  }

  }

}
</style>
