<template>
  <div>
    <error-alert
      :fields="{}"
      :error="onError"
    />
    <b-form>
      <validation-observer ref="receivedQuoteFromSupplierForm">
        <order-information class="mb-1" />
        <receive-warehouse class="mb-1" />
        <items />
        <other-charges
          class=" mb-1"
          :charge-error="chargeOnError"
        />
        <attachment
          class="mb-1"
          :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
        />
        <notes-and-totals />
      </validation-observer>
    </b-form>
    <form-bottom
      :form-ref="$refs"
      @onSubmitSuccess="onSubmitSuccess"
      @clear="onClear"
    />
    <b-s-warning-modal
      ref="confirm-modal"
    />
  </div>
</template>

<script>
import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { mapGetters } from 'vuex'
import Items from './components/Items.vue'
import Attachment from './components/Attachment.vue'
import OtherCharges from '../common/OtherCharges.vue'
import NotesAndTotals from './components/NotesAndTotals.vue'
import OrderInformation from './components/OrderInformation.vue'
import ReceiveWarehouse from './components/ReceiveWarehouse.vue'
import FormBottom from './components/FormBottom.vue'

import mainConfig from '../../../config'
import config from './config'

export default {
  name: 'Form',
  components: {
    BForm,
    Items,
    Attachment,
    FormBottom,
    ErrorAlert,
    OtherCharges,
    NotesAndTotals,
    OrderInformation,
    ReceiveWarehouse,
    ValidationObserver,
  },
  data() {
    return {
      error: {},
      onSubmit: false,
    }
  },
  computed: {
    ...mapGetters('purchase-cr', ['getReceivedQuoteFromSupplierTotalDataOfForms']),
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    chargeOnError() {
      return this.$store.state[this.MODULE_NAME].otherChargeError
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    purchaseFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].purchaseFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.getReceivedQuoteFromSupplierTotalDataOfForms) !== this.purchaseFormClone
    },
  },
  mounted() {
    this.$store.commit(`${this.MODULE_NAME}/SET_RECEIVE_WAREHOUSE_FORM`, {
      receiving_warehouse_id: this.quote.dispatch_warehouse,
      receive_method: this.quote.dispatch_method,
      dispatch_contact_id: { id: this.quote.dispatch_contact.id, full_name: `${this.quote.dispatch_contact.firstname} ${this.quote.dispatch_contact.lastname}` },
      external_shipping_notes: this.quote.external_shipping_notes,
    })
  },
  methods: {
    onSubmitSuccess() {
      this.onSubmit = true
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) {
        this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
      }
    },
    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
    },
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE } = mainConfig()
    const { storeMutationsList } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      storeMutationsList,
    }
  },
}
</script>
