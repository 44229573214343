<template>
  <div>
    <div v-if="quote.id">
      <portal to="body-top">
        <h3 class="text-center font-weight-bolder mb-1">
          {{ $t(RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_PREFIX) }}
          {{ getValueFromGivenObjectByKey(quote, 'id') }}
          {{ $t(RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_SUFFIX) }}
          —
          {{ getValueFromGivenObjectByKey(quote, 'supplier.cust_name_dba') }}
        </h3>
      </portal>
      <received-quote-from-supplier-form ref="received-quote-from-supplier-form" />
    </div>
    <div v-else>
      <skeleton v-if="isLoading && !errorStatus" />
      <div v-else-if="errorStatus && !isLoading">
        <component-not-found
          :error-status="errorStatus"
          :title="PAGE_TITLE"
          :button-link="{name: 'home-orders-purchase-cr-list'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { mapGetters } from 'vuex'
import Skeleton from '../components/create/received-qoute-from-supplier/components/Skeleton.vue'
import receivedQuoteFromSupplierForm from '../components/create/received-qoute-from-supplier/Form.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'ReceivedQuoteFromSupplier',
  components: {
    Skeleton,
    receivedQuoteFromSupplierForm,
  },
  data() {
    return {
      errorStatus: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('purchase-cr', ['getReceivedQuoteFromSupplierTotalDataOfForms']),
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['received-quote-from-supplier-form']) {
      this.$refs['received-quote-from-supplier-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  mounted() {
    this.$store.commit(`${this.MODULE_NAME}/GET`, { })
    this.isLoading = true

    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params?.id)
      .then(({ data: { data } }) => {
        const products = data.products?.map(product => ({
          ...product, price: +product?.price, discount: +product?.discount, isCollapseOpen: false,
        })) ?? []

        this.$store.commit(`${this.MODULE_NAME}/GET`, { ...data, products })
        this.$store.commit(`${this.MODULE_NAME}/SET_RECEIVE_ATTACHMENTS`, data.attachments)
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, data.other_charges.map(charge => ({ ...charge, tax: data.supplier.customer_detail.tax_rule.value })))
        this.$store.commit(`${this.MODULE_NAME}/SET_RECEIVE_WAREHOUSE_FORM`, {
          receiving_warehouse_id: data.dispatch_warehouse,
          receive_method: data.dispatch_method,
          dispatch_contact_id: { id: data.dispatch_contact.id, full_name: `${data.dispatch_contact.firstname} ${data.dispatch_contact.lastname}` },
          external_shipping_notes: data.external_shipping_notes,
        })

        this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, { ...data, tax_rule: data.supplier.customer_detail.tax_rule })
      })
      .catch(({ response }) => {
        this.errorStatus = response.status
      })
      .finally(() => {
        this.isLoading = false
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_PURCHASE_FORM_CLONE`, this.getReceivedQuoteFromSupplierTotalDataOfForms)
      })
  },
  setup() {
    const {
      PAGE_TITLE,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_PREFIX,
      RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_SUFFIX,
    } = config()

    return {
      PAGE_TITLE,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      getValueFromGivenObjectByKey,
      RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_PREFIX,
      RECEIVED_QUOTE_FROM_SUPPLIER_TITLE_SUFFIX,
    }
  },
}
</script>
