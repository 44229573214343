<template>
  <div
    class="bg-white"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(ORDER_INFORMATION_RECEIVE_TITLE) }}
    </h3>
    <b-form>
      <b-row>
        <b-col class="6 mt-1">
          <component
            :is="receiveWarehouseFields[field].type"
            v-for="field in [
              'receiving_warehouse_id',
              'receive_method',
            ]"
            :key="field"
            v-model="receiveWarehouse[field]"
            v-bind="getProps(field, receiveWarehouseFields)"
          />
        </b-col>
        <b-col class="6 mt-1">
          <component
            :is="receiveWarehouseFields[field].type"
            v-for="field in ['dispatch_contact_id', 'external_shipping_notes']"
            :key="field"
            v-model="receiveWarehouse[field]"
            v-bind="getProps(field, receiveWarehouseFields, true, 'dispatch_contact_id', getParentValue)"
          />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>

import { BCol, BForm, BRow } from 'bootstrap-vue'
import { getProps } from '@/@core/utils/utils'
import config from '../config'
import minConfig from '../../../../config'

export default {
  name: 'ReceiveWarehouse',
  components: {
    BCol,
    BRow,
    BForm,
  },
  computed: {
    receiveWarehouse() {
      return this.$store.state[this.MODULE_NAME].receiveWarehouseForm
    },
  },
  methods: {
    getParentValue() {
      return this.receiveWarehouse.receiving_warehouse_id
    },
  },
  setup() {
    const { receiveWarehouseFields, ORDER_INFORMATION_RECEIVE_TITLE } = config()
    const { MODULE_NAME } = minConfig()

    return {
      getProps,
      MODULE_NAME,
      receiveWarehouseFields,
      ORDER_INFORMATION_RECEIVE_TITLE,
    }
  },
}
</script>
