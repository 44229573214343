<template>
  <div ref="other-charges-ref">
    <h3 class="font-weight-bolder text-body">
      {{ $t(OTHER_CHARGES_OR_ITEMS_TITLE) }}
    </h3>
    <div
      class="bg-white p-1"
      style="border-radius: 5px"
    >
      <b-table-simple
        :class="`other-charges-items-table other-charges-items ${tableItemsLength === 1 ? 'other-charges-items-table--item-one': ''}`"
      >
        <b-thead>
          <b-tr>
            <b-td
              v-for="{key, label, thStyle} in columns"
              :key="key"
              :style="thStyle"
              class="font-weight-bolder"
            >
              {{ label }}
            </b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <tr
            v-for="({unit}, index) in otherChargesItemsOtherChargesArr"
            v-if="!isNewDirect"
            :key="index"
          >
            <td
              class="p-0"
              style="padding-left: 12px !important;"
            >
              <feather-icon
                icon="LTrashIcon"
                size="30"
                style="padding: 4px"
                class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
                @click="remove(index)"
              />
            </td>
            <td class="text-left pl-0">
              <component
                :is="chargeModelFields['name'].type"
                v-model="otherChargesItemsOtherChargesArr[index]['name']"
                class="other-charges-items__name"
                v-bind="getProps('name', chargeModelFields)"
              />
            </td>
            <td>
              <b-form-input
                v-model="otherChargesItemsOtherChargesArr[index]['unit']"
                class="other-charges-items__name other-charges-items__name--text-center other-charges-items__name--b-form-input"
                type="number"
                @input="getTotalItems"
              />
            </td>
            <td>
              <component
                :is="chargeModelFields['price'].type"
                v-model="otherChargesItemsOtherChargesArr[index]['price']"
                class="other-charges-items__name other-charges-items__name--text-center"
                v-bind="getProps('price', chargeModelFields)"
                @input="getTotalPrice"
              />
            </td>
            <td>
              <component
                :is="chargeModelFields['discount'].type"
                v-model="otherChargesItemsOtherChargesArr[index]['discount']"
                class="other-charges-items__name other-charges-items__name--text-center"
                v-bind="getProps('discount', chargeModelFields)"
                @input="getTotalDiscount"
              />
            </td>
            <td>
              <component
                :is="chargeModelFields['taxes'].type"
                v-model="otherChargesItemsOtherChargesArr[index]['taxes']"
                class="other-charges-items__name other-charges-items__name--text-center"
                v-bind="getProps('taxes', chargeModelFields)"
                @input="getTotalTaxes"
              />
              <!--              @input="getTotalSumOfTaxes"-->
            </td>
            <td>

              <span>${{ getSubtotal(otherChargesItemsOtherChargesArr[index]).toFixed(2) }}</span>

            </td>
          </tr>
          <tr
            v-for="({unit}, index) in otherChargesItemsOtherChargesArr"
            v-if="isNewDirect"
            :key="index"
          >
            <td
              class="p-0"
              style="padding-left: 12px !important;"
            >
              <feather-icon
                icon="LTrashIcon"
                size="30"
                style="padding: 4px"
                class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
                @click="remove(index)"
              />
            </td>
            <td class="text-left pl-0">
              <component
                :is="chargeModelFields['name'].type"
                v-model="otherChargesItemsOtherChargesArr[index]['name']"
                class="other-charges-items__name"
                v-bind="getProps('name', chargeModelFields)"
              />
            </td>
            <td>
              <b-form-input
                v-model="otherChargesItemsOtherChargesArr[index]['unit']"
                class="other-charges-items__name other-charges-items__name--text-center other-charges-items__name--b-form-input"
                type="number"
                @input="getTotalItems"
              />
            </td>
          </tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex">
        <b-form-input
          ref="charge-input-name"
          v-model="chargeName"
          style="width: 90%; border-top-right-radius: 0; border-bottom-right-radius: 0;"
          :class="`charge-input border-right-0 ${tableItemsLength ? 'charge-input-name' : ''} ${!isNewDirect ? 'direct-req' : ''}`"
          :placeholder="$t('Type...')"
          @keydown.tab.prevent="createChargeItem($event)"
          @keyup.enter="createChargeItem($event)"
        />
        <b-form-input
          v-if="isNewDirect"
          ref="charge-input-unit"
          v-model="chargeUnit"
          style="width: 10%; border-top-left-radius: 0; border-bottom-left-radius: 0;"
          :class="`charge-input text-center pr-2 border-left-0 ${tableItemsLength ? 'charge-input-number' : ''}`"
          type="number"
          @keydown.tab.prevent="createChargeItem($event)"
          @keyup.enter="createChargeItem($event)"
        />
      </div>
      <b-table-simple
        class="other-charges-items-table"
      >
        <b-thead>
          <b-tr>
            <b-td
              v-for="{key, thStyle} in columns"
              :key="key"
              :style="thStyle"
              style="height: 0; padding: 0"
            />
          </b-tr>
        </b-thead>
        <b-tfoot>
          <tr v-if="!isNewDirect">
            <td />
            <td
              class="text-right"
              style="width: 58%"
            >
              <b>{{ $t('TOTAL') }}</b>
            </td>
            <td style="padding-left: 25px">
              <b>{{ totalItems }}</b>
            </td>
            <td>
              <b>${{ totalPrice ? totalPrice.toFixed(2) : 0 }}</b>
            </td>
            <td>
              <b>${{ totalDiscount ? totalDiscount.toFixed(2) : 0 }}</b>
            </td>
            <td>
              <b><span>${{ totalTaxes.toFixed(2) }}</span></b>
            </td>
            <td>
              <!--              <b>${{ totalSubtotal ? totalSubtotal.toFixed(2) : 0 }}</b>-->
              <b>${{ totalSubtotal ? totalSubtotal.toFixed(2) : 0 }}</b>
            </td>
            <!--            <td>-->
            <!--              <b>${{ totalSumOfItems.toFixed(2) }}</b>-->
            <!--            </td>-->
          </tr>
          <tr v-if="isNewDirect">
            <td />
            <td
              class="text-right"
              style="width: 58%"
            >
              <b>{{ $t('TOTAL') }}</b>
            </td>
            <td style="padding-left: 25px">
              <b>{{ totalItems }}</b>
            </td>
          </tr>
        </b-tfoot>
      </b-table-simple>
      <p class="text-danger">
        {{ chargeError }}
      </p>
    </div>
  </div>
</template>
<script>

import config from '@/views/main/orders/view/purchase/components/create/direct-request-for-quote/config'
import { sumTotalAmountOfGivenArrayByProperty, getProps } from '@core/utils/utils'
import {
  BTableSimple, BThead, BTbody, BTfoot, BTr, BTd, BFormInput,
} from 'bootstrap-vue'
import LCurrencyMaskInput from '@/views/components/DynamicForm/components/LCurrencyMaskInput.vue'
import mainConfig from '../../../config'

export default {
  name: 'OtherCharges',
  components: {
    BTr,
    BTd,
    BThead,
    BTbody,
    BTfoot,
    BFormInput,
    BTableSimple,
    LCurrencyMaskInput,
  },
  props: {
    isNewDirect: {
      type: Boolean,
      default: false,
    },
    chargeError: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      chargeName: '',
      chargeUnit: null,
      totalItems: 0,
      totalDiscount: 0,
      totalPrice: 0,
      totalTaxes: 0,
      totalSubtotal: 0,
      totalSumOfItems: 0,
      totalSumOfTaxes: 0,
      taxRule: null,
    }
  },
  computed: {
    otherChargesItemsOtherChargesArr() {
      return this.otherChargesItems.otherCharges
    },
    otherChargesItems() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable // otherCharges
    },
    customerInformationForm() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    tax() {
      return this.taxRule?.value ?? 0
    },
    tableItemsLength() {
      return this.otherChargesItemsOtherChargesArr.length
    },
  },
  watch: {
    customerInformationForm({ tax_rule }) {
      // eslint-disable-next-line camelcase
      this.taxRule = tax_rule ?? 0
      // eslint-disable-next-line camelcase
      this.chargeModel.tax = tax_rule?.value ?? 0
    },
    tableItemsLength(val) {
      if (val) {
        this.getTotalItems()
        this.getTotalPrice()
        this.getTotalTaxes()
        this.getTotalDiscount()
        this.getTotalSubtotal()
        this.getTotalSumOfItems()
      }
    },
  },
  mounted() {
    this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE_REF`, this.$refs['other-charges-ref'])
  },
  methods: {
    remove(index) {
      this.otherChargesItems.otherCharges = this.otherChargesItemsOtherChargesArr.filter((_, idx) => idx !== index)
    },
    // eslint-disable-next-line consistent-return
    createChargeItem() {
      if (this.isNewDirect) {
        if (this.chargeName && !this.chargeUnit) {
          this.$refs['charge-input-unit'].focus()
        }

        if (this.chargeName && this.chargeUnit) {
          this.otherChargesItems.otherCharges.push({
            ...this.chargeModel,
            name: this.chargeName,
            unit: this.chargeUnit,
          })
          this.chargeName = ''
          this.chargeUnit = null
          this.getTotalItems()
          this.getTotalSumOfItems()
          this.$refs['charge-input-name'].focus()
        }
      } else if (this.chargeName) {
        this.otherChargesItems.otherCharges.push({
          ...this.chargeModel,
          name: this.chargeName,
          unit: 1,
        })
        this.chargeName = ''
        this.chargeUnit = null
        this.getTotalItems()
        this.getTotalSumOfItems()
        this.$refs['charge-input-name'].focus()
      }
    },
    getTotalItems() {
      this.totalItems = this.sumTotalAmountOfGivenArrayByProperty(this.otherChargesItemsOtherChargesArr, 'unit')
    },
    getTotalPrice() {
      this.totalPrice = this.sumTotalAmountOfGivenArrayByProperty(this.otherChargesItemsOtherChargesArr, 'price')
      this.getTotalSumOfItems()

      this.$emit('sumAction')
    },
    getTotalDiscount() {
      this.totalDiscount = this.sumTotalAmountOfGivenArrayByProperty(this.otherChargesItemsOtherChargesArr, 'discount')
      this.getTotalSumOfItems()

      this.$emit('sumAction')
    },
    getTotalTaxes() {
      this.totalTaxes = this.sumTotalAmountOfGivenArrayByProperty(this.otherChargesItemsOtherChargesArr, 'taxes')
      this.getTotalSumOfItems()
      this.$emit('sumAction')
    },
    getTotalSubtotal() {
      this.totalSubtotal = this.sumTotalAmountOfGivenArrayByProperty(this.otherChargesItemsOtherChargesArr, 'subtotal')
      this.getTotalSumOfItems()
      this.$emit('sumAction')
    },
    getTotalSumOfItems() {
      this.totalSumOfItems = this.sumTotalAmountOfGivenArrayByProperty(this.otherChargesItemsOtherChargesArr.map(charge => ({
        // eslint-disable-next-line no-mixed-operators
        itemTotalSum: (charge.subtotal - charge.discount) + ((charge.subtotal - charge.discount) * charge.tax / 100),
      })), 'itemTotalSum')
      this.getTotalSumOfTaxes()
    },
    getTotalSumOfTaxes() {
      this.totalSumOfTaxes = this.sumTotalAmountOfGivenArrayByProperty(this.otherChargesItemsOtherChargesArr.map(charge => ({
        // eslint-disable-next-line no-mixed-operators
        taxSum: (charge.subtotal - charge.discount) * charge.tax / 100,
      })), 'taxSum')
    },
    getSubtotal(value) {
      let subtotal = 0
      subtotal = (
        (
          (
            value.unit
                  * (value.price === undefined ? 0 : value.price)
          ) - (value.discount === undefined ? 0 : value.discount)
        ) + (value.taxes === undefined ? 0 : value.taxes)
      )
      value.subtotal = subtotal
      this.getTotalSubtotal()
      return subtotal
    },
  },
  setup(props) {
    const { isNewDirect } = props

    const {
      MODULE_NAME,
      chargeModel,
      chargeModelFields,
      OTHER_CHARGES_OR_ITEMS_TITLE,
      purchaseTableAutoSuggestOtherItemsTableColumns,
    } = config()

    const {
      tableColumnsOtherCharges,
      tableColumnsOtherChargesOnIsNewDirectRequest,
    } = mainConfig()

    const columns = isNewDirect ? tableColumnsOtherChargesOnIsNewDirectRequest : tableColumnsOtherCharges

    return {
      columns,
      getProps,
      chargeModel,
      MODULE_NAME,
      chargeModelFields,
      tableColumnsOtherCharges,
      OTHER_CHARGES_OR_ITEMS_TITLE,
      sumTotalAmountOfGivenArrayByProperty,
      tableColumnsOtherChargesOnIsNewDirectRequest,
      purchaseTableAutoSuggestOtherItemsTableColumns,
    }
  },
}
</script>
<style lang="scss">
.other-charges-items-table {
  margin-bottom: 0;

  thead td,
  tfoot td {
    border: 0 !important;
  }

  td {
    padding: 5px 12px;
  }

  tbody td,
  tfoot td {
    text-align: center;
  }

  tbody tr {
    height: 36px;
  }

  tbody td {
    padding: 0 12px;
  }

  tbody tr:last-child td:first-child {
    border-radius: 0 !important;
  }

  tbody tr:last-child td:last-child {
    border-radius: 0 !important;
  }

  &--item-one {
    tbody tr:last-child td:first-child {
      border-radius: 5px 0 0 0 !important;
    }

    tbody tr:last-child td:last-child {
      border-radius: 0 5px 0 0 !important;
    }
  }

  .other-charges-items__name {
    margin-bottom: 0;

    & input {
      width: 90%;
      padding: 0 2px;
      background: transparent;
      height: 30px;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #DDE3E7;
        box-shadow: none;
      }
    }

    &--text-center {
      input {
        width: 100%;
        text-align: center;
      }
    }

    &--b-form-input {
      width: 100%;
      text-align: center;
      padding: 0 2px;
      background: transparent;
      height: 30px;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #DDE3E7;
        box-shadow: none;
      }
    }
  }

  .other-charges-items__mask {
    margin-bottom: 0;
    width: 100%;

    & .form-control {
      text-align: center;
      padding: 0 4px;
      height: 32px !important;
      background: transparent !important;
      border-color: transparent;

      &:hover,
      &:focus {
        border: 1px solid #DDE3E7;
      }
    }
  }

}

.charge-input {
  height: 36px;

  &:focus {
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid #DDE3E7;
  }

}

.charge-input-name {
  border-radius: 0 0 0 5px;
}

.charge-input-number {
  border-radius: 0 0 5px 0;
}

.charge-input-name,
.charge-input-number {
  border-top: none;

  &:focus {
    border-top: none;
  }
}

.direct-req {
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #DDE3E7 !important;
}
</style>
